import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Paper from "material-ui/Paper";

import { requestJwt, isLoggedIn, getJwtError } from "../ducks/jwt";

class LoginAuth extends Component {
  componentDidMount() {
    const queryMap = new URLSearchParams(window.location.search);

    const { isLoggedIn, requestJwt } = this.props;

    if (!isLoggedIn && queryMap.has("uid") && queryMap.has("token")) {
      requestJwt(queryMap.get("uid"), queryMap.get("token"));
    }
  }

  render() {
    const { isLoggedIn, error } = this.props;

    const queryMap = new URLSearchParams(window.location.search);

    return (
      <div>
        {!isLoggedIn &&
          !error &&
          queryMap.has("uid") &&
          queryMap.has("token") && <div>Request JWT</div>}
        {!isLoggedIn && !(queryMap.has("uid") && queryMap.has("token")) && (
          <div>
            Kann kein JWT anfragen, die Parameter stimmen nicht überein.
          </div>
        )}
        {error && (
          <Paper zDepth={2}>
            <h2>Die Anmeldung war nicht möglich.</h2>
            <code>{error.toString()}</code>
          </Paper>
        )}
        {isLoggedIn && <Redirect to="/" />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
  error: getJwtError(state)
});

export default connect(
  mapStateToProps,
  {
    requestJwt
  }
)(LoginAuth);
