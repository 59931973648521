import { createSelector } from 'reselect'

const actionPrefix = 'fire-editor/sideMenu'

// Actions
export const TOGGLE_NAVIGATION = `${actionPrefix}/TOGGLE_NAVIGATION`

// Reducer
export default function reducer (state = 'closed', action) {
  if (action.type !== TOGGLE_NAVIGATION) {
    return state
  }

  if (state === 'closed') {
    return 'open'
  } else {
    return 'closed'
  }
}

// Action Creators
export const toggleNavigation = () => ({
  type: TOGGLE_NAVIGATION
})

// Selectors
const getStatus = (state) => state.sideMenu

export const isSideMenuOpened = createSelector(
  [getStatus],
  (status) => status === 'open'
)
