import { schema } from "normalizr";

export const incidentSchema = new schema.Entity(
  "incident",
  {},
  { idAttribute: "_id" }
);
export const incidentArraySchema = [incidentSchema];

export const newsSchema = new schema.Entity("news", {}, { idAttribute: "_id" });
export const newsArraySchema = [newsSchema];

export const districtSchema = new schema.Entity(
  "district",
  {},
  { idAttribute: "_id" }
);
export const districtArraySchema = [districtSchema];

export const operationTypeSchema = new schema.Entity(
  "operationType",
  {},
  { idAttribute: "_id" }
);
export const operationTypeArraySchema = [operationTypeSchema];

export const firebrigadeSchema = new schema.Entity(
  "firebrigade",
  {},
  { idAttribute: "_id" }
);
export const firebrigadeArraySchema = [firebrigadeSchema];

export const vehicleSchema = new schema.Entity(
  "vehicle",
  {},
  { idAttribute: "_id" }
);
export const vehicleArraySchema = [vehicleSchema];

export const personSchema = new schema.Entity(
  "person",
  {},
  { idAttribute: "_id" }
);
export const personArraySchema = [personSchema];
