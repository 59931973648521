import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors } from "redux-form";
import { Redirect } from "react-router-dom";

import { requestDistricts, getDistrictsAsList } from "../ducks/districts";
import {
  requestFirebrigades,
  getFirebrigadesAsList
} from "../ducks/firebrigades";
import {
  requestOperationTypes,
  getOperationTypesAsList
} from "../ducks/operationType";

import {
  createIncident,
  changeFormStep,
  getFormStep
} from "../ducks/incidents";

import { requestPeople, getPeople } from "../ducks/people";

import { requestVehicles, getVehiclesAsList } from "../ducks/vehicles";

import IncidentForm from "./IncidentForm";

class InidentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  componentDidMount() {
    this.props.requestDistricts();
    this.props.requestOperationTypes();
    this.props.requestFirebrigades();
    this.props.requestVehicles();
    this.props.requestPeople();
    this.props.changeFormStep(0);
  }

  render() {
    const {
      districts,
      operationTypes,
      firebrigades,
      vehicles,
      people,
      value,
      createIncident,
      step,
      changeFormStep,
      errors
    } = this.props;

    const handleSubmit = incident =>
      createIncident(incident).then(() => {
        this.setState({ submitted: true });
      });

    return (
      <div>
        {this.state.submitted && <Redirect to="/incidents" />}
        {!this.state.submitted &&
          districts &&
          operationTypes &&
          firebrigades &&
          vehicles &&
          people && (
            <IncidentForm
              districts={districts}
              operationTypes={operationTypes}
              firebrigades={firebrigades}
              vehicles={vehicles}
              people={people}
              value={value}
              step={step}
              onNextStep={changeFormStep}
              onSubmit={handleSubmit}
              errors={errors}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  districts: getDistrictsAsList(state),
  operationTypes: getOperationTypesAsList(state),
  firebrigades: getFirebrigadesAsList(state),
  vehicles: getVehiclesAsList(state),
  people: getPeople(state),
  step: getFormStep(state),
  value: getFormValues("incidentForm")(state),
  errors: getFormSyncErrors("incidentForm")(state)
});

export default connect(
  mapStateToProps,
  {
    requestDistricts,
    requestOperationTypes,
    requestFirebrigades,
    requestVehicles,
    requestPeople,
    createIncident,
    changeFormStep
  }
)(InidentCreate);
