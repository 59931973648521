import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

import { vehicleArraySchema } from '../schemas'
import fetchWithJwt from '../lib/fetchWithJwt'
import { getJwt } from '../ducks/jwt'

// Actions
export const REQUEST_VEHICLES_STARTED = 'REQUEST_VEHICLES_STARTED'
export const REQUEST_VEHICLES_SUCCESSED = 'REQUEST_VEHICLES_SUCCESSED'
export const REQUEST_VEHICLES_FAILED = 'REQUEST_VEHICLES_FAILED'

// Action Creators
export const requestVehicles = () => (dispatch, getStore) => {
  dispatch({ type: REQUEST_VEHICLES_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt('/api/admin/vehicles', {jwt})
    .then(response => response.json())
    .then(
      (vehicles) => dispatch({
        type: REQUEST_VEHICLES_SUCCESSED,
        ...normalize(vehicles, vehicleArraySchema)
      }),
      (error) => dispatch({ type: REQUEST_VEHICLES_FAILED, error })
    )
}

// Selectors
export const getVehicles = (state) => state.entities.vehicle
export const getVehiclesAsList = createSelector(
  [getVehicles],
  (vehicles) => vehicles && Object.values(vehicles)
)

