import React from 'react'
import IconMenu from 'material-ui/IconMenu'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import { connect } from 'react-redux'
import moment from 'moment'

import { getUserFromToken, logout } from '../ducks/jwt'

const Logged = ({user, logout, ...props}) => (
  <IconMenu
    {...props}
    iconButtonElement={
      <IconButton><MoreVertIcon /></IconButton>
    }
    targetOrigin={{horizontal: 'right', vertical: 'top'}}
    anchorOrigin={{horizontal: 'right', vertical: 'top'}}
  >
    <MenuItem disabled primaryText={`Hallo ${user.email}`} />
    <MenuItem disabled primaryText={`Anmeldung läuft ${moment.unix(user.exp).fromNow()} ab`} />
    <MenuItem primaryText='Abmelden' onClick={logout} />
  </IconMenu>
)
Logged.muiName = 'IconMenu'

const mapStateToProps = state => ({
  user: getUserFromToken(state)
})

export default connect(mapStateToProps, {
  logout
})(Logged)
