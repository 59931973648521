import React from 'react'
import { connect } from 'react-redux'

import Dialog from 'material-ui/Dialog'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import LinearProgress from 'material-ui/LinearProgress'

import { requestUserToken } from '../ducks/passwordlessLogin'
import { isLoggedIn } from '../ducks/jwt'

const LoginDialog = ({isLoggedIn, requestUserToken, isFetching, error, requestOk, email}) => {
  let emailInput

  const actions = [
    <RaisedButton
      label='Anmelde-E-Mail anfordern'
      primary
      fullWidth
      onClick={() => requestUserToken(emailInput.value)}
      disabled={isFetching}
    />
  ]

  return (
    <Dialog
      title='Du bist nicht angemeldet'
      actions={actions}
      modal
      open={!isLoggedIn}
      autoScrollBodyContent
    >
      <p>Bitte melde dich an, um den <code>Fire-Editor</code> nutzen zu können.</p>
      <p>Dieser Dienst nutzt eine Anmeldemethode mit dem Namen "Passwordless". Wie der Name suggeriert, gibt es keine Passwörter. Stattdessen gibtst du nur deine eMail-Adresse an und wartest auf eine Bestätigungsemail mit einen Link. Danach bist du auch schon angemeldet.</p>

      {isFetching && <LinearProgress mode='indeterminate' />}

      {error &&
        <div>
          <p>Es gab ein Fehler: <code>{error.message}</code></p>
          <p>Sollte der Fehler nicht verschwinden, sende bitte die Fehlermeldung an deinen Administrator.</p>
          <p>Du kannst es aber noch einmal probieren:</p>
        </div>
      }

      {requestOk &&
        <div>
          Deine Anfrage wurde verarbeitet. Schaue bitte in deinem eMail-Postfach mit der Adresse <code>{email}</code> nach.
        </div>
      }

      {!isFetching && !requestOk &&
        <TextField
          hintText='me@email.com'
          floatingLabelText='Deine E-Mail-Adresse'
          fullWidth
          disabled={isFetching || requestOk}
          ref={(el) => { emailInput = el ? el.input : undefined }}
        />
      }
    </Dialog>
  )
}

const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state),
  isFetching: state.passwordlessLogin.isFetching,
  error: state.passwordlessLogin.error,
  requestOk: state.passwordlessLogin.requestOk,
  email: state.passwordlessLogin.email
})

export default connect(mapStateToProps, {
  requestUserToken
})(LoginDialog)
