import React from "react";

import IconButton from "material-ui/IconButton";
import ActionDelete from "material-ui/svg-icons/action/delete";
import ActionNoteAdd from "material-ui/svg-icons/action/note-add";
import Subheader from "material-ui/Subheader";
import { Flex, Box } from "reflexbox";
import { Field } from "redux-form";
import { TextField } from "redux-form-material-ui";

const ExternalLinks = ({ fields, meta }) => {
  return (
    <div>
      <Subheader>Externe Verweise</Subheader>
      {fields.map((member, index) => (
        <div>
          <Flex align="flex-end">
            <Box w={(1, 3 / 12)} mr={1}>
              <Field
                name={`${member}.title`}
                component={TextField}
                hintText="Der Rasende Reporter"
                floatingLabelText="Medium"
                fullWidth
              />
            </Box>
            <Box w={(1, 8 / 12)} mx={1}>
              <Field
                name={`${member}.url`}
                component={TextField}
                hintText="https://www.irgendeine-adresse.de/mit-pfad"
                floatingLabelText="URL"
                fullWidth
              />
            </Box>
            <Box w={(1, 1 / 12)} ml={1}>
              <IconButton onClick={() => fields.remove(index)}>
                <ActionDelete />
              </IconButton>
            </Box>
          </Flex>
        </div>
      ))}
      <Flex align="flex-end">
        <Box w={[0, 1 / 12]} mr={1} />
        <Box w={[1, 11 / 12]} ml={1}>
          <IconButton onClick={() => fields.push({})}>
            <ActionNoteAdd />
          </IconButton>
        </Box>
      </Flex>
    </div>
  );
};

export default ExternalLinks;
