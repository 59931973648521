import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import Drawer from "material-ui/Drawer";
import MenuItem from "material-ui/MenuItem";
import AppBar from "material-ui/AppBar";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import Paper from "material-ui/Paper";
import NavigationClose from "material-ui/svg-icons/navigation/close";
import NavigationOpen from "material-ui/svg-icons/navigation/menu";
import WhatsHotIcon from "material-ui/svg-icons/social/whatshot";
import EventNoteIcon from "material-ui/svg-icons/notification/event-note";
import Home from "material-ui/svg-icons/action/home";

import Logged from "../containers/Logged";

const Login = props => (
  <FlatButton
    {...props}
    label="Login"
    containerElement={<Link to="/login" />}
  />
);
Login.muiName = "FlatButton";

const MainMenu = ({ open, onToggleNavigation, isLoggedIn, children }) => {
  const programName = "Fire Editor";
  return (
    <div>
      <Drawer open={open}>
        <AppBar
          title={programName}
          onLeftIconButtonClick={onToggleNavigation}
          iconElementLeft={
            <IconButton>
              <NavigationClose />
            </IconButton>
          }
        />

        <MenuItem
          primaryText="Home"
          leftIcon={<Home />}
          containerElement={<Link to="/" />}
        />

        <MenuItem
          primaryText="Einsätze"
          leftIcon={<WhatsHotIcon />}
          containerElement={<Link to="/incidents" />}
        />

        <MenuItem
          primaryText="Neuigkeiten"
          leftIcon={<EventNoteIcon />}
          containerElement={<Link to="/news" />}
        />
      </Drawer>
      <Paper
        style={{
          marginLeft: open ? 256 : 0,
          minHeight: "100vh",
          backgroundColor: "#EEEEEE"
        }}
      >
        <AppBar
          style={{
            position: "fixed",
            width: window.innerWidth - (open ? 256 : 0)
          }}
          title={!open ? programName : ""}
          onLeftIconButtonClick={onToggleNavigation}
          iconElementLeft={
            !open ? (
              <IconButton>
                <NavigationOpen />
              </IconButton>
            ) : (
              <div />
            )
          }
          iconElementRight={isLoggedIn ? <Logged /> : <Login />}
        />
        <div style={{ paddingTop: 64 }}>{children}</div>
      </Paper>
    </div>
  );
};
MainMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleNavigation: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default MainMenu;
