import React, {Component} from 'react'
import { connect } from 'react-redux'

import ImagesList from '../components/ImagesList'
import ImageDropzone from '../components/ImageDropzone'

import { Flex, Box } from 'reflexbox'

import {
  requestNews,
  addImageToNewsItem,
  removeMediaFromNewsItem,
  getNewsItemById
} from '../ducks/news'

class IncidentImages extends Component {
  componentWillMount () {
    this.props.requestNews(this.props.newsId)
  }

  render () {
    const { newsId, newsItem, addImageToNewsItem, removeMediaFromNewsItem } = this.props
    if (!newsItem) {
      return <div />
    }

    const { media } = newsItem

    const onDrop = ([file]) => addImageToNewsItem(newsId, file)

    const onDelete = (imageId) => removeMediaFromNewsItem(newsId, imageId)

    return (
      <Flex column>
        { media && media.length > 0 ? (
          <Box p={2}>
            <ImagesList media={media} onDelete={onDelete} />
          </Box>
          ) : null
        }
        <Box p={2}>
          <ImageDropzone onDrop={onDrop} />
        </Box>
      </Flex>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  newsId: ownProps.match.params.newsId,
  newsItem: getNewsItemById(state, ownProps.match.params.newsId)
})

export default connect(mapStateToProps, {
  requestNews,
  addImageToNewsItem,
  removeMediaFromNewsItem
})(IncidentImages)
