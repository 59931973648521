import { createSelector } from 'reselect'

// jwt

const getJwtTokem = (state) => state.jwt.token
export const isLoggedIn = createSelector(
  [getJwtTokem],
  (token) => {
    if (!token) return
    const user = getTokenValue(token)
    if (!isTokenValid(user)) return
    return user
  }
)

function getTokenValue (jwt) {
  if (typeof jwt !== 'string') {
    return undefined
  }
  const payloadStringAsBase64 = jwt.split('.')[1]
  const payloadString = window.atob(payloadStringAsBase64)
  const payload = JSON.parse(payloadString)
  return payload
}

function isTokenValid ({exp} = {}) {
  // exp is seconds, Date.now() is ms
  return (Date.now() / 1000) < exp
}

// incidents
const getIncidentsArea = (state) => state.incidents
export const getAllIncidentsIds = (state) => getIncidentsArea(state).allIncidents
export const getIncidentsCount = (state) => getAllIncidentsIds(state).length
export const getIncidentsListPage = (state) => getIncidentsArea(state).page
export const getIncidentsListItemsPerPage = (state) => getIncidentsArea(state).itemsPerPage
export const getSelectedIncident = (state) => getIncidentsArea(state).selectedIncident
export const getIncidentMapById = (state) => state.entities.incident

const fillIds = (ids, byId) => ids
  .map(id => byId[id])

const fillIdsWithIncidents = (ids, incidentMapById) => fillIds(ids, incidentMapById)
  .map(incident => ({
    ...incident,
    alerting_at: new Date(incident.alerting_at),
    exit_at: new Date(incident.exit_at),
    return_at: new Date(incident.return_at)
  }))

const sliceForPages = (items, page, itemsPerPage) => {
  return items.slice(page * itemsPerPage, ((page + 1) * itemsPerPage))
}

export const getIncidents = createSelector(
  [getAllIncidentsIds, getIncidentMapById],
  (allIncidents, incidentMapById) => fillIdsWithIncidents(allIncidents, incidentMapById)
)
export const getIncidentsPerPage = createSelector(
  [getAllIncidentsIds, getIncidentMapById, getIncidentsListPage, getIncidentsListItemsPerPage],
  (allIncidents, incidentMapById, page, itemsPerPage) => {
    return fillIdsWithIncidents(
      sliceForPages(allIncidents, page, itemsPerPage),
      incidentMapById
    )
  }
)

// import { getNewsListPerPage, getSelectedNewsItem, getNewsPage, getNewsItemsCount, getNewsItemsPerPage } from '../reducers'
// news
const getNewsArea = (state) => state.news
const getAllNewsIds = (state) => getNewsArea(state).allNews
export const getNewsItemsCount = (state) => getAllNewsIds(state).length
export const getNewsListPage = (state) => getNewsArea(state).page
export const getNewsListItemsPerPage = (state) => getNewsArea(state).itemsPerPage
export const getSelectedNewsItem = (state) => getNewsArea(state).selectedNews
const getNewsMapById = (state) => state.entities.news

export const getNewsListPerPage = createSelector(
  [getAllNewsIds, getNewsMapById, getNewsListPage, getNewsListItemsPerPage],
  (ids, newsMapById, page, itemsPerPage) => {
    return fillIds(
      sliceForPages(ids, page, itemsPerPage),
      newsMapById
    )
  }
)
