import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from "material-ui/Table";

import IconButton from "material-ui/IconButton";
import LeftIcon from "material-ui/svg-icons/navigation/chevron-left";
import RightIcon from "material-ui/svg-icons/navigation/chevron-right";
import OpenInNewIcon from "material-ui/svg-icons/action/open-in-new";
import MenuItem from "material-ui/MenuItem";
import DropDownMenu from "material-ui/DropDownMenu";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";

import ImagesButton from "./ImagesButton";
import EditButton from "./EditButton";
import FloatingNewButton from "./FloatingNewButton";

class NewsTableRow extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.newsItem !== nextProps.newsItem ||
      this.props.selected !== nextProps.selected
    );
  }

  render() {
    const { selected, newsItem, ...props } = this.props;
    return (
      <TableRow key={newsItem._id} selected={selected} {...props}>
        {props.children[0]}
        <TableRowColumn>{newsItem.title}</TableRowColumn>
        <TableRowColumn style={{ width: 120 }}>
          {moment(newsItem.created_at).format("DD.MM.YYYY")},{" "}
          {moment(newsItem.created_at).format("HH:mm")}
        </TableRowColumn>
      </TableRow>
    );
  }
}

const News = ({
  news,
  activeRow,
  onRowSelect,
  onNextPageClick,
  onPrevPageClick,
  page,
  itemsPerPage,
  totalItems
}) => {
  const onRowSelection = ([i]) => {
    if (typeof i !== "number") {
      return onRowSelect();
    }
    onRowSelect(news[i]._id);
  };

  const tableHeight = window.innerHeight - 64 - 56 - 59 - 51;

  const activeItem = news.find(incident => activeRow === incident._id);
  const activeItemHasMedia =
    activeItem && activeItem.media && activeItem.media.length > 0;

  const activeItemIsPublic = activeItem && activeItem.published;
  const previewButton = (
    <IconButton
      disabled={!activeItemIsPublic}
      tooltip="Webseite"
      href={`https://feuerwehr-bedburg-hau.de/neuigkeiten/${activeRow}`}
      target="blank"
    >
      <OpenInNewIcon />
    </IconButton>
  );

  const maxPage = Math.ceil(totalItems / itemsPerPage);

  return (
    <div>
      <Toolbar>
        <ToolbarGroup firstChild>
          <DropDownMenu value={"all_news"} onChange={() => undefined}>
            <MenuItem value={"all_news"} primaryText="Alle Neuigkeiten" />
          </DropDownMenu>
        </ToolbarGroup>
        <ToolbarGroup>
          <EditButton
            activeRow={activeRow}
            containerElement={<Link to={`/news/${activeRow}`} />}
          />

          <ImagesButton
            activeRow={activeRow}
            activeItemHasMedia={activeItemHasMedia}
            containerElement={<Link to={`/news/${activeRow}/images`} />}
          />

          {previewButton}
        </ToolbarGroup>
      </Toolbar>

      <FloatingNewButton to="/news/new" />

      <Table onRowSelection={onRowSelection} height={`${tableHeight}px`}>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn>Titel</TableHeaderColumn>
            <TableHeaderColumn style={{ width: 120 }}>Datum</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody deselectOnClickaway={false}>
          {news &&
            news.map((newsItem, i) => (
              <NewsTableRow
                key={newsItem._id}
                newsItem={newsItem}
                selected={activeRow === newsItem._id}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableRowColumn colSpan="4" style={{ textAlign: "right" }}>
              {(page - 1) * itemsPerPage + 1}-
              {Math.min(page * itemsPerPage, totalItems)} von {totalItems}
              <IconButton onClick={onPrevPageClick} disabled={page <= 1}>
                <LeftIcon />
              </IconButton>
              <IconButton onClick={onNextPageClick} disabled={page >= maxPage}>
                <RightIcon />
              </IconButton>
            </TableRowColumn>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default News;
