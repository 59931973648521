import React from "react";
import { connect } from "react-redux";

import { Route } from "react-router-dom";

import LoginDialog from "../containers/LoginDialog";
import { isLoggedIn } from "../ducks/jwt";

let MatchWhenAuthorized = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => (isLoggedIn ? <Component {...props} /> : <LoginDialog />)}
  />
);
const mapStateToProps = state => ({
  isLoggedIn: isLoggedIn(state)
});

export default connect(mapStateToProps)(MatchWhenAuthorized);
