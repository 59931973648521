import React, { Component } from 'react'
import { connect } from 'react-redux'

import News from '../components/NewsList'

import { requestNewsList, selectedNewsItem, changeNewsListPage } from '../ducks/news'
import { getNewsListPerPage, getSelectedNewsItem, getNewsListPage, getNewsItemsCount, getNewsListItemsPerPage } from '../selectors'

class NewsList extends Component {
  componentDidMount () {
    this.props.requestNewsList()
  }

  render () {
    const {
      news,
      activeRow,
      page,
      itemsPerPage,
      totalItems,
      selectedNewsItem,
      changeNewsListPage
    } = this.props

    return (
      <News
        news={news}
        activeRow={activeRow}
        onNextPageClick={() => changeNewsListPage(page + 1)}
        onPrevPageClick={() => changeNewsListPage(page - 1)}
        page={page + 1}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onRowSelect={selectedNewsItem}
      />
    )
  }
}

const mapStateToProps = state => ({
  news: getNewsListPerPage(state),
  activeRow: getSelectedNewsItem(state),
  page: getNewsListPage(state),
  itemsPerPage: getNewsListItemsPerPage(state),
  totalItems: getNewsItemsCount(state)
})

export default connect(mapStateToProps, {
  requestNewsList,
  selectedNewsItem,
  changeNewsListPage
})(NewsList)
