import React from "react";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from "material-ui/Table";

import IconButton from "material-ui/IconButton";
import LeftIcon from "material-ui/svg-icons/navigation/chevron-left";
import RightIcon from "material-ui/svg-icons/navigation/chevron-right";
import OpenInNewIcon from "material-ui/svg-icons/action/open-in-new";
import MenuItem from "material-ui/MenuItem";
import DropDownMenu from "material-ui/DropDownMenu";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";

import ImagesButton from "./ImagesButton";
import EditButton from "./EditButton";
import FloatingNewButton from "./FloatingNewButton";

class IncidentTableRow extends React.Component {
  render() {
    const { selected, incident, districts, ...props } = this.props;
    return (
      <TableRow selected={selected} {...props}>
        {props.children[0]}
        <TableRowColumn>{incident.name}</TableRowColumn>
        <TableRowColumn>
          {districts[incident.district_id].name}
          {incident.street ? ", " + incident.street : ""}
          {incident.place ? ", " + incident.place : ""}
        </TableRowColumn>
        <TableRowColumn style={{ width: 120 }}>
          {moment(incident.alerting_at).format("DD.MM.YYYY")},{" "}
          {moment(incident.alerting_at).format("HH:mm")}
        </TableRowColumn>
      </TableRow>
    );
  }
}

const Inidents = ({
  incidents,
  districts,
  activeRow,
  onRowSelect,
  onNextPageClick,
  onPrevPageClick,
  page,
  itemsPerPage,
  totalItems,
  match
}) => {
  const onRowSelection = ([i]) => {
    if (typeof i !== "number") {
      return onRowSelect();
    }
    onRowSelect(incidents[i]._id);
  };

  const tableHeight = window.innerHeight - 64 - 56 - 59 - 51;

  const activeItem = incidents.find(incident => activeRow === incident._id);
  const activeItemHasMedia =
    activeItem && activeItem.media && activeItem.media.length > 0;
  const activeItemIsPublic = activeItem && activeItem.published;

  const maxPage = Math.ceil(totalItems / itemsPerPage);

  return (
    <div>
      <Toolbar>
        <ToolbarGroup firstChild>
          <DropDownMenu value={"all_incidents"} onChange={() => undefined}>
            <MenuItem value={"all_incidents"} primaryText="Alle Einsätze" />
          </DropDownMenu>
        </ToolbarGroup>
        <ToolbarGroup>
          <EditButton
            activeRow={activeRow}
            containerElement={<Link to={`${match.path}/${activeRow}`} />}
          />
          <ImagesButton
            activeRow={activeRow}
            activeItemHasMedia={activeItemHasMedia}
            containerElement={<Link to={`${match.path}/${activeRow}/images`} />}
          />
          <IconButton
            disabled={!activeItemIsPublic}
            tooltip="Webseite"
            href={`https://feuerwehr-bedburg-hau.de/einsätze/${activeRow}`}
            target="blank"
          >
            <OpenInNewIcon />
          </IconButton>
        </ToolbarGroup>
      </Toolbar>

      <FloatingNewButton to={`${match.path}/new`} />

      <Table onRowSelection={onRowSelection} height={`${tableHeight}px`}>
        <TableHeader>
          <TableRow>
            <TableHeaderColumn>Name</TableHeaderColumn>
            <TableHeaderColumn>Straße, Hausnummer, Lage</TableHeaderColumn>
            <TableHeaderColumn style={{ width: 120 }}>Datum</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody deselectOnClickaway={false}>
          {incidents &&
            districts &&
            incidents.map((incident, i) => (
              <IncidentTableRow
                key={incident._id}
                selected={activeRow === incident._id}
                incident={incident}
                districts={districts}
              />
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableRowColumn colSpan="4" style={{ textAlign: "right" }}>
              {(page - 1) * itemsPerPage + 1}-
              {Math.min(page * itemsPerPage, totalItems)} von {totalItems}
              <IconButton onClick={onPrevPageClick} disabled={page <= 1}>
                <LeftIcon />
              </IconButton>
              <IconButton onClick={onNextPageClick} disabled={page >= maxPage}>
                <RightIcon />
              </IconButton>
            </TableRowColumn>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default withRouter(Inidents);
