import React from "react";
import { reduxForm, Field, Fields, FieldArray } from "redux-form";
import sortBy from "lodash/sortBy";
// import debounce from 'lodash/debounce'
import moment from "moment";

import { Flex, Box } from "reflexbox";

import { Card, CardActions, CardTitle, CardText } from "material-ui/Card";
import MenuItem from "material-ui/MenuItem";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Subheader from "material-ui/Subheader";
import {
  SelectField,
  TextField,
  Toggle,
  DatePicker,
  TimePicker
} from "redux-form-material-ui";
import { Step, Stepper, StepButton } from "material-ui/Stepper";

import FormItemList from "../components/FormItemList";
import MapInput from "../components/MapInput";
import ExternalLinks from "../components/ExternalLinks";

/**
 * @param {string} value
 * @returns number
 */
const normalizeNumber = value => {
  if (!value || value === "" || value.endsWith(".")) {
    return value;
  }
  return parseFloat(value);
};

// let getAddress = (search) => {
//   search = window.encodeURI(search)
//   return window.fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${search}&language=de&region=de&key=AIzaSyBAvFYIhdaMIjEe0egJwAUxKVIuDiwyt7c`)
//     .then(d => d.json())
//     .then(answer => {
//       console.log(answer)
//       return answer
//     })
// }
// getAddress = debounce(getAddress, 1000)

const StepOne = ({ districts, operationTypes, nextButton }) => (
  <Card>
    <CardTitle title="Basisdaten" subtitle="Das wichtigste zusammengefasst" />
    <CardText>
      <Field
        name="name"
        component={TextField}
        hintText="Hilfloses Einhorn auf Straße"
        floatingLabelText="Titel des Einsatzes"
        fullWidth
      />
      <Field
        name="district_id"
        component={SelectField}
        floatingLabelText="Ortsteil"
        fullWidth
      >
        {districts.map(district => (
          <MenuItem
            key={district._id}
            value={district._id}
            primaryText={district.name}
          />
        ))}
      </Field>
      <Flex>
        <Box w={[1, 9 / 12]} mr={1}>
          <Field
            name="street"
            component={TextField}
            hintText="Kleine Straße"
            floatingLabelText="Straße"
            fullWidth
          />
        </Box>
        <Box w={[1, 3 / 12]} ml={1}>
          <Field
            name="housenumber"
            component={TextField}
            hintText="1a"
            floatingLabelText="Hausnummer"
            fullWidth
          />
        </Box>
      </Flex>
      <Field
        name="place"
        component={TextField}
        hintText="Am Kreisverkehr"
        floatingLabelText="Lage"
        fullWidth
      />
      <Flex wrap>
        <Box w={[1, 1 / 2]}>
          <Field
            name="coordinates[0]"
            component={TextField}
            normalize={normalizeNumber}
            hintText="51.7"
            floatingLabelText="Geographische Breite"
            fullWidth
          />
        </Box>
        <Box w={[1, 1 / 2]}>
          <Field
            name="coordinates[1]"
            component={TextField}
            normalize={normalizeNumber}
            hintText="6.1"
            floatingLabelText="Geographische Länge"
            fullWidth
          />
        </Box>
      </Flex>
      <Fields
        names={["coordinates[0]", "coordinates[1]"]}
        component={MapInput}
        containerElement={
          <div style={{ minHeight: "200px", height: "60vh" }} />
        }
        mapElement={<div style={{ minHeight: "200px", height: "60vh" }} />}
      />
      <Field
        name="operation_type_id"
        component={SelectField}
        floatingLabelText="Einsatzart"
        fullWidth
      >
        {operationTypes.map(operationType => (
          <MenuItem
            key={operationType._id}
            value={operationType._id}
            primaryText={operationType.name}
            secondaryText={operationType.abbreviation}
          />
        ))}
      </Field>
    </CardText>
    <CardActions>{nextButton}</CardActions>
  </Card>
);

const isValidDate = value => moment(value).isAfter(moment("2000", "YYYY"));

const formatToValidDate = value => {
  if (!isValidDate(value)) {
    return "";
  }
  return value;
};

const StepTwo = ({
  firebrigades,
  vehicles,
  people,
  backButton,
  nextButton,
  value
}) => (
  <Card>
    <CardTitle title="Alarm" subtitle="Für wen und wann ging's los?" />
    <CardText>
      <Subheader>Löschgruppen</Subheader>
      <Field
        name="firebrigade_ids"
        component={FormItemList}
        dataSource={firebrigades}
        dataField="_id"
        labelField="name"
      />

      <Subheader>Fahrzeuge</Subheader>
      <Field
        name="vehicle_ids"
        component={FormItemList}
        dataSource={vehicles}
        dataField="_id"
        labelField={vehicle => `${vehicle.radio_call_sign} (${vehicle.name})`}
      />

      <Field
        name="alerting_at"
        component={DatePicker}
        format={formatToValidDate}
        hintText="Alarmierung"
        floatingLabelText="Datum der Alarmierung"
        fullWidth
      />
      <Field
        name="alerting_at"
        component={TimePicker}
        format={formatToValidDate}
        hintText="Alarmierung"
        floatingLabelText="Uhrzeit der Alarmierung"
        fullWidth
      />

      <Field
        name="exit_at"
        component={DatePicker}
        format={formatToValidDate}
        defaultDate={value ? value.alerting_at : null}
        hintText="Ausfahrt"
        floatingLabelText="Datum der Ausfahrt"
        fullWidth
      />
      <Field
        name="exit_at"
        component={TimePicker}
        format={formatToValidDate}
        defaultTime={value ? value.alerting_at : null}
        hintText="Ausfahrt"
        floatingLabelText="Uhrzeit der Ausfahrt"
        fullWidth
      />

      <Field
        name="return_at"
        component={DatePicker}
        format={formatToValidDate}
        defaultDate={value ? value.alerting_at : null}
        hintText="Rückkehr"
        floatingLabelText="Datum der Rückkehr"
        fullWidth
      />
      <Field
        name="return_at"
        component={TimePicker}
        format={formatToValidDate}
        defaultTime={value ? value.alerting_at : null}
        hintText="Rückkehr"
        floatingLabelText="Uhrzeit der Rückkehr"
        fullWidth
      />

      <Subheader>Einsatzleiter</Subheader>
      <Field
        name="incident_commander_ids"
        component={FormItemList}
        dataSource={people}
        dataField="_id"
        labelField={person => `${person.name.first} ${person.name.last}`}
      />
    </CardText>
    <CardActions>
      {backButton}
      {nextButton}
    </CardActions>
  </Card>
);

const StepThree = ({ backButton, nextButton }) => (
  <Card>
    <CardTitle title="Texte" subtitle="Was ist überhaupt geschehen?" />
    <CardText>
      <Field
        name="short_description"
        component={TextField}
        hintText="Es lag ein hilfloses Einhorn auf Straße"
        floatingLabelText="Kurzbeschreibung"
        fullWidth
        multiLine
        rowsMax={3}
      />
      <Field
        name="description"
        component={TextField}
        hintText="Hier gehört ein längerer Text mit weiteren Informationen hinein."
        floatingLabelText="Beschreibung"
        fullWidth
        multiLine
        rowsMax={10}
      />

      <Field
        name="social_media.facebook"
        component={TextField}
        hintText="Was soll in der Facebook-Meldung für ein Hinweis erscheinen?"
        floatingLabelText="Text für Facebook"
        fullWidth
        multiLine
        rowsMax={3}
      />
      <Field
        name="social_media.twitter"
        component={TextField}
        hintText="Was soll in der Twitter-Meldung für ein Hinweis erscheinen?"
        floatingLabelText="Text für Twitter"
        fullWidth
        multiLine
        rowsMax={3}
      />
    </CardText>
    <CardActions>
      {backButton}
      {nextButton}
    </CardActions>
  </Card>
);

const StepFour = ({ backButton, nextButton }) => (
  <Card>
    <CardTitle title="Externe Verweise" subtitle="Wer hat drüber berichtet?" />
    <CardText>
      <FieldArray name="external_links" component={ExternalLinks} />
    </CardText>
    <CardActions>
      {backButton}
      {nextButton}
    </CardActions>
  </Card>
);

const StepFive = ({ submitting, handleSubmit, backButton, errors, invalid }) => (
  <Card>
    <CardTitle
      title="Absenden"
      subtitle="Wähle aus, ob der Einsatz veröffentlicht werden soll und speichere ihn ab"
    />
    <CardText>
      <Field
        name="published"
        component={Toggle}
        label="Auf Webseite, Twitter und Facebook veröffentlicht?"
        labelPosition="right"
      />
    </CardText>
    {Object.keys(errors).length > 0 ? (
      <CardText>
        <p>Es gibt noch einige Fehler im Formular.</p>
        <dl>
          {Object.keys(errors).map(error => (
            <div>
              <dt>{error}</dt>
              <dd>{errors[error]}</dd>
            </div>
          ))}
        </dl>
      </CardText>
    ) : null}
    <CardActions>
      {backButton}
      <RaisedButton
        label="Abspeichern"
        primary
        disabled={submitting || invalid}
        onClick={handleSubmit}
      />
    </CardActions>
  </Card>
);

let IncidentForm = ({
  districts,
  operationTypes,
  firebrigades,
  vehicles,
  people,
  value,
  step,
  onNextStep,
  errors,
  form: { submitting, handleSubmit }
}) => {
  // operation type
  operationTypes = sortBy(operationTypes, ["abbreviation", "name"]);

  people = sortBy(people, ["name.last", "name.first"]);

  // filter only vehicles for selected firebrigade
  vehicles = vehicles.filter(vehicle => {
    if (!value || !value.firebrigade_ids) {
      return true;
    }
    const firebrigadeId = vehicle.firebrigade_id;
    if (!firebrigadeId) {
      // leiter-problem
      return true;
    }
    return value.firebrigade_ids.includes(firebrigadeId);
  });
  vehicles = sortBy(vehicles, ["radio_call_sign"]);

  const handleNext = () => {
    if (step < 4) {
      onNextStep(step + 1);
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      onNextStep(step - 1);
    }
  };

  const backButton = (
    <FlatButton
      label="zurück"
      disabled={step === 0}
      onClick={handlePrev}
      style={{ marginRight: 12 }}
    />
  );

  const nextButton = (
    <RaisedButton
      label="weiter"
      disabled={step === 4}
      primary
      onClick={handleNext}
    />
  );

  return (
    <form noValidate autoComplete="off">
      <Flex column>
        <Box p={2}>
          <Stepper linear={false} activeStep={step}>
            <Step>
              <StepButton onClick={() => onNextStep(0)}>Basisdaten</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => onNextStep(1)}>Alarm</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => onNextStep(2)}>Texte</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => onNextStep(3)}>
                Extene Verweise
              </StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => onNextStep(4)}>Absenden</StepButton>
            </Step>
          </Stepper>
        </Box>
        <Box p={2}>
          {step === 0 && (
            <StepOne
              backButton={backButton}
              nextButton={nextButton}
              districts={districts}
              operationTypes={operationTypes}
            />
          )}
          {step === 1 && (
            <StepTwo
              backButton={backButton}
              nextButton={nextButton}
              firebrigades={firebrigades}
              vehicles={vehicles}
              people={people}
              value={value}
            />
          )}
          {step === 2 && (
            <StepThree backButton={backButton} nextButton={nextButton} />
          )}
          {step === 3 && (
            <StepFour backButton={backButton} nextButton={nextButton} />
          )}
          {step === 4 && (
            <StepFive
              backButton={backButton}
              nextButton={nextButton}
              submitting={submitting}
              handleSubmit={handleSubmit}
              errors={errors}
            />
          )}
        </Box>
      </Flex>
    </form>
  );
};

const validate = values => {
  const errors = {};
  // Title
  if (!values.name) {
    errors.name = "Gebe umbedingt einen Titel ein.";
  }
  // district_id
  if (!values.district_id) {
    errors.district_id = "Wähle einen Ort aus der Liste aus.";
  }
  // street
  if (!values.street) {
    errors.street = "Trage hier eine Straße ein.";
  }

  if (!(values.alerting_at instanceof Date)) {
    errors.alerting_at = "Gebe an, wann die Alarmierung war.";
  }
  if (
    isValidDate(values.exit_at) &&
    moment(values.exit_at).isBefore(values.alerting_at)
  ) {
    errors.exit_at = "Die Ausfahrt kann nicht vor der Alarmierung liegen";
  }
  if (
    isValidDate(values.return_at) &&
    moment(values.return_at).isBefore(values.exit_at)
  ) {
    errors.return_at = "Die Rückkehr kann nicht vor der Ausfahrt liegen";
  }

  if (!values.vehicle_ids || values.vehicle_ids.length === 0) {
    errors.vehicle_ids = "Du musst mindestens ein Fahrzeug auswählen.";
  }

  if (
    !values.incident_commander_ids ||
    values.incident_commander_ids.length === 0
  ) {
    errors.incident_commander_ids = "Wähle mindestens einen Einsatzleiter aus";
  }

  return errors;
};

const warn = values => {
  const warns = {};
  // title
  if (values.name && values.name.length < 8) {
    warns.name = "Du solltest hier einen aussagekräftigen Titel eingeben";
  }
  // coordinates
  if (!values.coordinates || !values.coordinates[0]) {
    warns.coordinates = warns.coordinates || [];
    warns.coordinates[0] =
      "Du solltest Geokoordinaten eingeben. Nutze dafür diese Felder oder die Karte unten.";
  }
  if (!values.coordinates || !values.coordinates[1]) {
    warns.coordinates = warns.coordinates || [];
    warns.coordinates[1] =
      "Du solltest Geokoordinaten eingeben. Nutze dafür diese Felder oder die Karte unten.";
  }

  if (!values.social_media || !values.social_media.facebook) {
    warns.social_media = warns.social_media || {};
    warns.social_media.facebook =
      "Du hast keinen Text für Facebook angegeben. Es wird damit keine Neuigkeit auf Facebook veröffentlicht. Das ist kein Fehler wenn du das auch nicht willst.";
  }
  if (!values.social_media || !values.social_media.twitter) {
    warns.social_media = warns.social_media || {};
    warns.social_media.twitter =
      "Du hast keinen Text für Twitter angegeben. Es wird damit keine Neuigkeit auf Twitter veröffentlicht. Das ist kein Fehler wenn du das auch nicht willst.";
  }

  if (!values.short_description) {
    warns.short_description =
      "Eine kurze Beschreibung solltest du schon eingeben";
  }

  return warns;
};

// Decorate with redux-form
IncidentForm = reduxForm({
  propNamespace: "form",
  form: "incidentForm",
  validate,
  warn
})(IncidentForm);

export default IncidentForm;
