const initialState = {
  incident: {},
  district: {},
  news: {}
}

export default function reducer (state = initialState, action) {
  if (!action.entities) {
    return state
  }

  const newState = {
    ...state
  }

  const newKeys = Object.keys(action.entities)
  for (let key of newKeys) {
    newState[key] = {...newState[key], ...action.entities[key]}
  }

  return newState
}
