import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

// FIXME: Import this from duck
// import { REQUEST_USER_TOKEN_STARTED } from '../actions'
const REQUEST_USER_TOKEN_STARTED = 'REQUEST_USER_TOKEN_STARTED'

// Actions
export const REQUEST_JWT_STARTED = 'REQUEST_JWT_STARTED'
export const REQUEST_JWT_SUCCESSED = 'REQUEST_JWT_SUCCESSED'
export const REQUEST_JWT_FAILED = 'REQUEST_JWT_FAILED'

export const JWT_LOGOUT = 'JWT_LOGOUT'

export const localStorageField = 'fire-token'

// Reducers
const token = (state = null, action) => {
  switch (action.type) {
    case REQUEST_USER_TOKEN_STARTED:
    case REQUEST_JWT_STARTED:
    case REQUEST_JWT_FAILED:
    case JWT_LOGOUT:
      return null

    case REQUEST_JWT_SUCCESSED:
      return action.token

    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST_JWT_STARTED:
      return true
    case REQUEST_JWT_FAILED:
    case REQUEST_JWT_SUCCESSED:
      return false
    default:
      return state
  }
}

const error = (state = null, action) => {
  switch (action.type) {
    case REQUEST_JWT_STARTED:
    case REQUEST_JWT_SUCCESSED:
    case JWT_LOGOUT:
      return null
    case REQUEST_JWT_FAILED:
      return action.error
    default:
      return state
  }
}

const reducer = combineReducers({
  token,
  isFetching,
  error
})

export default reducer

// Action Creater

export const logout = () => {
  window.localStorage.removeItem(localStorageField)
  return { type: JWT_LOGOUT }
}

const requestJwtSuccess = (token) => {
  window.localStorage.setItem(localStorageField, token)
  return { type: REQUEST_JWT_SUCCESSED, token }
}

const requestJwtStarted = (uid, token) => {
  window.localStorage.removeItem(localStorageField)
  return { type: REQUEST_JWT_STARTED, uid, token }
}

export const requestJwt = (uid, token) => (dispatch) => {
  dispatch(requestJwtStarted(uid, token))
  return window.fetch('/api/auth/verify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ uid, token })
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error("Request didn't send an ok status. Status is " + response.status)
      }
    })
    .then(
      ({token}) => {
        if (token) {
          dispatch(requestJwtSuccess(token))
        } else {
          window.localStorage.removeItem(localStorageField)
          dispatch({ type: REQUEST_JWT_FAILED })
        }
      },
      (error) => {
        dispatch({ type: REQUEST_JWT_FAILED, error })
      }
    )
}

// Selector

export const getJwtError = (state) => state.jwt.error

export const getJwt = (state) => state.jwt.token
export const isLoggedIn = createSelector(
  [getJwt],
  (token) => {
    if (!token) return
    const user = getTokenValue(token)
    if (!isTokenValid(user)) return
    return user
  }
)

export const getUserFromToken = ({jwt: state}) => {
  if (!state.token) {
    return
  }
  const user = getTokenValue(state.token)
  if (!isTokenValid(user)) {
    return
  }
  return user
}

function getTokenValue (jwt) {
  if (typeof jwt !== 'string') {
    return undefined
  }
  const payloadStringAsBase64 = jwt.split('.')[1]
  const payloadString = window.atob(payloadStringAsBase64)
  const payload = JSON.parse(payloadString)
  return payload
}

function isTokenValid ({exp} = {}) {
  // exp is seconds, Date.now() is ms
  return (Date.now() / 1000) < exp
}
