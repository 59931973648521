import { combineReducers } from 'redux'

import { reducer as form } from 'redux-form'
import districts from './districts'
import sideMenu from './sideMenu'
import entities from './entities'
import jwt from './jwt'
import incidents from './incidents'
import passwordlessLogin from './passwordlessLogin'
import news from './news'

export default combineReducers({
  districts,
  sideMenu,
  entities,
  jwt,
  incidents,
  news,
  passwordlessLogin,
  form
})
