import "whatwg-fetch";

import moment from "moment";
import "moment/locale/de";

import React from "react";
import ReactDOM from "react-dom";
import Root from "./containers/Root";
import "./index.css";
import { localStorageField as jwtField } from "./ducks/jwt";
import configureStore from "./store/configureStore";

moment.locale("de");

const store = configureStore({
  sideMenu: "open",
  jwt: {
    token: window.localStorage.getItem(jwtField)
  }
});

ReactDOM.render(<Root store={store} />, document.getElementById("root"));
