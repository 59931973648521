import React from "react";
import { reduxForm, Field } from "redux-form";

import { Flex, Box } from "reflexbox";

import { Card, CardActions, CardTitle, CardText } from "material-ui/Card";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import Subheader from "material-ui/Subheader";
import { TextField, Toggle } from "redux-form-material-ui";
import { Step, Stepper, StepButton } from "material-ui/Stepper";

import FormItemList from "../components/FormItemList";

const StepOne = ({ districts, firebrigades, nextButton }) => (
  <Card>
    <CardTitle title="Basisdaten" subtitle="Worum geht's überhaupt" />
    <CardText>
      <Field
        name="title"
        component={TextField}
        hintText="Ein Herz für Einhörner"
        floatingLabelText="Titel des Einsatzes"
        fullWidth
      />

      <Subheader>Verbundende Orte</Subheader>
      <Field
        name="district_ids"
        component={FormItemList}
        dataSource={districts}
        dataField="_id"
        labelField="name"
      />

      <Subheader>Verbundende Löschgruppen</Subheader>
      <Field
        name="firebrigade_ids"
        component={FormItemList}
        dataSource={firebrigades}
        dataField="_id"
        labelField="name"
      />
    </CardText>
    <CardActions>{nextButton}</CardActions>
  </Card>
);

const StepTwo = ({ backButton, nextButton }) => (
  <Card>
    <CardTitle title="Texte" subtitle="Was ist überhaupt geschehen?" />
    <CardText>
      <Field
        name="body"
        component={TextField}
        hintText={
          <span>
            Hier gehört der eigentliche Inhalt hinein. Der Text kann nach den
            Regeln von Markdown formatiert werden. <br />
            Einleitung und weiterer Inhalt kann mit{" "}
            <code>{"<!-- more -->"}</code> getrennt werden.
          </span>
        }
        floatingLabelText="Beschreibung"
        fullWidth
        multiLine
        rows={2}
        rowsMax={15}
      />

      <Field
        name="social_media.facebook"
        component={TextField}
        hintText="Was soll in der Facebook-Meldung für ein Hinweis erscheinen?"
        floatingLabelText="Text für Facebook"
        fullWidth
        multiLine
        rowsMax={3}
      />
      <Field
        name="social_media.twitter"
        component={TextField}
        hintText="Was soll in der Twitter-Meldung für ein Hinweis erscheinen?"
        floatingLabelText="Text für Twitter"
        fullWidth
        multiLine
        rowsMax={3}
      />
    </CardText>
    <CardActions>
      {backButton}
      {nextButton}
    </CardActions>
  </Card>
);

const StepThree = ({ submitting, handleSubmit, backButton }) => (
  <Card>
    <CardTitle
      title="Absenden"
      subtitle="Wähle aus, ob der Einsatz veröffentlicht werden soll und speichere ihn ab"
    />
    <CardText>
      <Field
        name="published"
        component={Toggle}
        label="Auf Webseite, Twitter und Facebook veröffentlicht?"
        labelPosition="right"
      />
    </CardText>
    <CardActions>
      {backButton}
      <RaisedButton
        label="Abspeichern"
        primary
        disabled={submitting}
        onClick={handleSubmit}
      />
    </CardActions>
  </Card>
);

let NewsForm = ({
  districts,
  firebrigades,
  step,
  onNextStep,
  submitting,
  handleSubmit
}) => {
  const handleNext = () => {
    if (step < 3) {
      onNextStep(step + 1);
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      onNextStep(step - 1);
    }
  };

  const backButton = (
    <FlatButton
      label="zurück"
      disabled={step === 0}
      onClick={handlePrev}
      style={{ marginRight: 12 }}
    />
  );

  const nextButton = (
    <RaisedButton
      label="weiter"
      disabled={step === 4}
      primary
      onClick={handleNext}
    />
  );

  return (
    <form noValidate autoComplete="off">
      <Flex column>
        <Box p={2}>
          <Stepper linear={false} activeStep={step}>
            <Step>
              <StepButton onClick={() => onNextStep(0)}>Basisdaten</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => onNextStep(1)}>Texte</StepButton>
            </Step>
            <Step>
              <StepButton onClick={() => onNextStep(2)}>Absenden</StepButton>
            </Step>
          </Stepper>
        </Box>
        <Box p={2}>
          {step === 0 && (
            <StepOne
              backButton={backButton}
              nextButton={nextButton}
              districts={districts}
              firebrigades={firebrigades}
            />
          )}
          {step === 1 && (
            <StepTwo backButton={backButton} nextButton={nextButton} />
          )}
          {step === 2 && (
            <StepThree
              backButton={backButton}
              submitting={submitting}
              handleSubmit={handleSubmit}
            />
          )}
        </Box>
      </Flex>
    </form>
  );
};

// Decorate with redux-form
NewsForm = reduxForm({
  form: "newsForm"
})(NewsForm);

export default NewsForm;
