import React from 'react'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import {
  red500,
  red700,
  indigoA200
} from 'material-ui/styles/colors'
import getMuiTheme from 'material-ui/styles/getMuiTheme'

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: red500,
    primary2Color: red700,
    accent1Color: indigoA200
  }
})

const ThemeProvider = ({children}) => {
  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      {children}
    </MuiThemeProvider>
  )
}

export default ThemeProvider
