const fetchWithJwt = (url, {jwt, body, json = false, ...options}) => {
  options.headers = options.headers || {}
  options.headers['Authorization'] = `Bearer ${jwt}`
  if (body instanceof window.FormData) {
    options.body = body
  } else if (json || typeof body === 'object') {
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(body)
  }

  return window.fetch(url, options)
}

export default fetchWithJwt
