import React from "react";
import { Route, Switch } from "react-router-dom";

import IncidentsList from "../containers/IncidentsList";
import IncidentEdit from "../containers/IncidentEdit";
import IncidentCreate from "../containers/IncidentCreate";
import IncidentImages from "../containers/IncidentImages";

const IncidentsModule = ({ match }) => (
  <Switch>
    <Route path={match.path} exact component={IncidentsList} />

    <Route path={`${match.path}/new`} component={IncidentCreate} />

    <Route path={`${match.path}/:incidentId`} exact component={IncidentEdit} />

    <Route
      path={`${match.path}/:incidentId/images`}
      exact
      component={IncidentImages}
    />
  </Switch>
);

export default IncidentsModule;
