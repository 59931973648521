import React from 'react'
import Dropzone from 'react-dropzone'
import { Flex } from 'reflexbox'

import FileUploadIcon from 'material-ui/svg-icons/file/file-upload'

import Paper from 'material-ui/Paper'

const styles = {
  textAlign: 'center',
  height: 150,
  border: '1px solid dashed'
}

const dropzoneStyles = {
  active: {
    background: '#aea'
  },
  reject: {
    borderStyle: 'solid'
  }

}

const ImageDropzone = ({onDrop}) => {
  return (
    <Paper>
      <Dropzone onDrop={onDrop} style={{}} accept='image/*' activeStyle={dropzoneStyles.active}>
        <Flex column justify='space-around' align='center' style={styles}>
          <FileUploadIcon style={{height: 64, width: 64}} />
          <div>Klicke oder lege eine Datei auf dieses Feld ab</div>
        </Flex>
      </Dropzone>
    </Paper>
  )
}

export default ImageDropzone
