import { normalize } from 'normalizr'

import fetchWithJwt from './fetchWithJwt'

const fetchAndNormalize = async (url, schema, options) => {
  const response = await fetchWithJwt(url, options)
  if (response.status < 200 || response.status >= 300) {
    throw new Error(`Status ${response.status} ${response.statusText} is not ok`)
  }
  const data = await response.json()

  return normalize(data, schema)
}

export default fetchAndNormalize
