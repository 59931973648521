import { normalize } from 'normalizr'
import { newsArraySchema, newsSchema } from '../schemas'
import fetchWithJwt from '../lib/fetchWithJwt'
import { getJwt } from './jwt'

// Actions

export const NEWS_ITEM_SELECTED = 'NEWS_ITEM_SELECTED'
export const NEWS_ITEM_LIST_CHANGE_PAGE = 'NEWS_ITEM_LIST_CHANGE_PAGE'
export const NEWS_ITEM_FORM_STEP = 'NEWS_ITEM_FORM_STEP'
export const REQUEST_NEWS_LIST_STARTED = 'REQUEST_NEWS_LIST_STARTED'
export const REQUEST_NEWS_LIST_SUCCESSED = 'REQUEST_NEWS_LIST_SUCCESSED'
export const REQUEST_NEWS_LIST_FAILED = 'REQUEST_NEWS_LIST_FAILED'
export const REQUEST_NEWS_ITEM_STARTED = 'REQUEST_NEWS_ITEM_STARTED'
export const REQUEST_NEWS_ITEM_SUCCESSED = 'REQUEST_NEWS_ITEM_SUCCESSED'
export const REQUEST_NEWS_ITEM_FAILED = 'REQUEST_NEWS_ITEM_FAILED'
export const SAVE_NEWS_ITEM_STARTED = 'SAVE_NEWS_ITEM_STARTED'
export const SAVE_NEWS_ITEM_SUCCESSED = 'SAVE_NEWS_ITEM_SUCCESSED'
export const SAVE_NEWS_ITEM_FAILED = 'SAVE_NEWS_ITEM_FAILED'
export const CREATE_NEWS_ITEM_STARTED = 'CREATE_NEWS_ITEM_STARTED'
export const CREATE_NEWS_ITEM_SUCCESSED = 'CREATE_NEWS_ITEM_SUCCESSED'
export const CREATE_NEWS_ITEM_FAILED = 'CREATE_NEWS_ITEM_FAILED'
export const ADD_MEDIA_TO_NEWS_ITEM_STARTED = 'ADD_MEDIA_TO_NEWS_ITEM_STARTED'
export const ADD_MEDIA_TO_NEWS_ITEM_SUCCESSED = 'ADD_MEDIA_TO_NEWS_ITEM_SUCCESSED'
export const ADD_MEDIA_TO_NEWS_ITEM_FAILED = 'ADD_MEDIA_TO_NEWS_ITEM_FAILED'
export const REMOVE_MEDIA_FROM_NEWS_ITEM_STARTED = 'REMOVE_MEDIA_FROM_NEWS_ITEM_STARTED'
export const REMOVE_MEDIA_FROM_NEWS_ITEM_SUCCESSED = 'REMOVE_MEDIA_FROM_NEWS_ITEM_SUCCESSED'
export const REMOVE_MEDIA_FROM_NEWS_ITEM_FAILED = 'REMOVE_MEDIA_FROM_NEWS_ITEM_FAILED'

// Reducter

const initialState = {
  allNews: [],
  page: 0,
  itemsPerPage: 50,
  formStep: 0
}

const news = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_NEWS_LIST_SUCCESSED:
      return {
        ...state,
        allNews: action.result
      }
    case NEWS_ITEM_SELECTED:
      return {
        ...state,
        selectedNews: action.newsId
      }
    case NEWS_ITEM_LIST_CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      }
    case NEWS_ITEM_FORM_STEP:
      return {
        ...state,
        formStep: action.step
      }
    case SAVE_NEWS_ITEM_SUCCESSED:
    case CREATE_NEWS_ITEM_SUCCESSED:
      return {
        ...state,
        formStep: 0
      }

    default:
      return state
  }
}

export default news

// Action creators

export const selectedNewsItem = (newsId) => ({type: NEWS_ITEM_SELECTED, newsId})
export const changeNewsListPage = (page) => ({type: NEWS_ITEM_LIST_CHANGE_PAGE, page})
export const changeNewsFormStep = (step) => ({type: NEWS_ITEM_FORM_STEP, step})

export const requestNewsList = () => (dispatch, getStore) => {
  dispatch({ type: REQUEST_NEWS_LIST_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt('/api/admin/news', {jwt})
    .then(response => response.json())
    .then(
      (newsList) => dispatch({
        type: REQUEST_NEWS_LIST_SUCCESSED,
        ...normalize(newsList, newsArraySchema)
      }),
      (error) => dispatch({ type: REQUEST_NEWS_LIST_FAILED, error })
    )
}

export const requestNews = (id) => (dispatch, getStore) => {
  dispatch({ type: REQUEST_NEWS_ITEM_STARTED, newsId: id })

  const jwt = getJwt(getStore())

  return fetchWithJwt(`/api/admin/news/${id}`, {jwt})
    .then(response => response.json())
    .then(
      (newsItem) => dispatch({
        type: REQUEST_NEWS_ITEM_SUCCESSED,
        newsId: id,
        ...normalize(newsItem, newsSchema)
      }),
      (error) => dispatch({ type: REQUEST_NEWS_ITEM_FAILED, error, newsId: id })
    )
}

export const saveNewsItem = (id, newsItem) => (dispatch, getStore) => {
  dispatch({ type: SAVE_NEWS_ITEM_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt(`/api/admin/news/${id}`, {
    method: 'PUT',
    body: newsItem,
    jwt
  })
    .then(response => response.json())
    .then(
      (newsItem) => dispatch({
        type: SAVE_NEWS_ITEM_SUCCESSED,
        ...normalize(newsItem, newsSchema)
      }),
      (error) => dispatch({ type: SAVE_NEWS_ITEM_FAILED, error })
    )
}

export const createNewsItem = (newsItem) => (dispatch, getStore) => {
  dispatch({ type: CREATE_NEWS_ITEM_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt('/api/admin/news', {
    method: 'POST',
    body: newsItem,
    jwt
  })
    .then(response => response.json())
    .then(
      (newsItem) => dispatch({
        type: CREATE_NEWS_ITEM_SUCCESSED,
        ...normalize(newsItem, newsSchema)
      }),
      (error) => dispatch({ type: CREATE_NEWS_ITEM_FAILED, error })
    )
}

export const addImageToNewsItem = (id, file) => (dispatch, getStore) => {
  dispatch({ type: ADD_MEDIA_TO_NEWS_ITEM_STARTED })

  const jwt = getJwt(getStore())

  const data = new window.FormData()
  data.append('file', file)

  return fetchWithJwt(`/api/admin/news/${id}/media`, {
    method: 'POST',
    body: data,
    jwt
  })
    .then(response => response.json())
    .then(
      (newsItem) => dispatch({
        type: ADD_MEDIA_TO_NEWS_ITEM_SUCCESSED,
        ...normalize(newsItem, newsSchema)
      }),
      (error) => dispatch({ type: ADD_MEDIA_TO_NEWS_ITEM_FAILED, error })
    )
}

export const removeMediaFromNewsItem = (id, imageId) => (dispatch, getStore) => {
  dispatch({ type: REMOVE_MEDIA_FROM_NEWS_ITEM_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt(`/api/admin/news/${id}/media/${imageId}`, {
    method: 'DELETE',
    jwt
  })
    .then(response => response.json())
    .then(
      (newsItem) => dispatch({
        type: REMOVE_MEDIA_FROM_NEWS_ITEM_SUCCESSED,
        ...normalize(newsItem, newsSchema)
      }),
      (error) => dispatch({ type: REMOVE_MEDIA_FROM_NEWS_ITEM_FAILED, error })
    )
}

// Selectors

export const loginError = (state) => state.news.error

export const getSelectedNews = (state) => state.news.selectedNews
export const getPage = (state) => state.news.page
export const getFormStep = (state) => state.news.formStep
export const getItemsPerPage = (state) => state.news.itemsPerPage

const fillIdsWithNews = (ids, byId) => ids
  .map(id => byId[id])

export const getNewsCount = (state) => state.news.allNews.length

export const getNewsListPerPage = (state, byId) => {
  return fillIdsWithNews(
    state.news.allNews
      .slice(state.news.page * state.news.itemsPerPage, ((state.news.page + 1) * state.news.itemsPerPage - 1)),
    byId
  )
}

export const getNewsList = (state, byId) => fillIdsWithNews(state.news.allNews, byId)

export const getNewsItemById = (state, id) => state.entities.news[id]
