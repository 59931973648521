import React, { Component } from 'react'
import { connect } from 'react-redux'

import Incidents from '../components/Incidents'

import { requestIncidents, selectedIncident, changeIncidentListPage, getIncidentsCount } from '../ducks/incidents'
import { requestDistricts, getDistricts } from '../ducks/districts'
import { getIncidentsPerPage, getSelectedIncident, getIncidentsListPage, getIncidentsListItemsPerPage } from '../selectors'

class InidentsList extends Component {
  componentDidMount () {
    this.props.requestIncidents()
    this.props.requestDistricts()
  }

  render () {
    const {
      incidents,
      districts,
      activeRow,
      page,
      itemsPerPage,
      totalItems,
      selectedIncident,
      changeIncidentListPage
    } = this.props

    return (
      <Incidents
        incidents={incidents}
        districts={districts}
        activeRow={activeRow}
        onNextPageClick={() => changeIncidentListPage(page + 1)}
        onPrevPageClick={() => changeIncidentListPage(page - 1)}
        page={page + 1}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        onRowSelect={selectedIncident}
      />
    )
  }
}

const mapStateToProps = state => ({
  incidents: getIncidentsPerPage(state),
  districts: getDistricts(state),
  activeRow: getSelectedIncident(state),
  page: getIncidentsListPage(state),
  itemsPerPage: getIncidentsListItemsPerPage(state),
  totalItems: getIncidentsCount(state)
})

export default connect(mapStateToProps, {
  requestIncidents,
  requestDistricts,
  selectedIncident,
  changeIncidentListPage
})(InidentsList)
