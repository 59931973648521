import React from "react";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import IconButton from "material-ui/IconButton";

const EditButton = ({ activeRow, ...props }) => (
  <IconButton disabled={!activeRow} tooltip="Bearbeiten" {...props}>
    <EditIcon />
  </IconButton>
);

export default EditButton;
