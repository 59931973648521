import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Redirect } from "react-router-dom";

import { createNewsItem, changeNewsFormStep, getFormStep } from "../ducks/news";

import {
  requestFirebrigades,
  getFirebrigadesAsList
} from "../ducks/firebrigades";
import { requestDistricts, getDistrictsAsList } from "../ducks/districts";

import NewsItemForm from "./NewsItemForm";

class NewsItemCreate extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  componentDidMount() {
    this.props.requestDistricts();
    this.props.requestFirebrigades();
    this.props.changeNewsFormStep(0);
  }

  render() {
    const {
      districts,
      firebrigades,
      value,
      createNewsItem,
      step,
      changeNewsFormStep
    } = this.props;

    const handleSubmit = newsItem =>
      createNewsItem(newsItem).then(() => {
        this.setState({ submitted: true });
      });

    return (
      <div>
        {this.state.submitted && <Redirect to="/news" />}
        {!this.state.submitted && districts && firebrigades && (
          <NewsItemForm
            districts={districts}
            firebrigades={firebrigades}
            value={value}
            step={step}
            onNextStep={changeNewsFormStep}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  districts: getDistrictsAsList(state),
  firebrigades: getFirebrigadesAsList(state),
  step: getFormStep(state),
  value: getFormValues("incidentForm")(state)
});

export default connect(
  mapStateToProps,
  {
    requestDistricts,
    requestFirebrigades,
    createNewsItem,
    changeNewsFormStep
  }
)(NewsItemCreate);
