import React from "react";
import CollectionIcon from "material-ui/svg-icons/image/photo-library";
import IconButton from "material-ui/IconButton";

const ImagesButton = ({ activeItemHasMedia, activeRow, ...props }) => (
  <IconButton disabled={!activeRow} tooltip="Bilder" {...props}>
    <CollectionIcon color={activeItemHasMedia ? "black" : "#777"} />
  </IconButton>
);

export default ImagesButton;
