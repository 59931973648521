import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'
import { normalize } from 'normalizr'

import { firebrigadeArraySchema } from '../schemas'
import fetchWithJwt from '../lib/fetchWithJwt'
import { getJwt } from './jwt'

// Actions
export const REQUEST_FIREBRIGADES_STARTED = 'REQUEST_FIREBRIGADES_STARTED'
export const REQUEST_FIREBRIGADES_SUCCESSED = 'REQUEST_FIREBRIGADES_SUCCESSED'
export const REQUEST_FIREBRIGADES_FAILED = 'REQUEST_FIREBRIGADES_FAILED'

// Action Creators
export const requestFirebrigades = () => (dispatch, getStore) => {
  dispatch({ type: REQUEST_FIREBRIGADES_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt('/api/admin/firebrigades', {jwt})
    .then(response => response.json())
    .then(
      (firebrigades) => dispatch({
        type: REQUEST_FIREBRIGADES_SUCCESSED,
        ...normalize(firebrigades, firebrigadeArraySchema)
      }),
      (error) => dispatch({ type: REQUEST_FIREBRIGADES_FAILED, error })
    )
}

// Selectors
export const getFirebrigades = (state) => state.entities.firebrigade
export const getFirebrigadesAsList = createSelector(
  [getFirebrigades],
  (firebrigades) => firebrigades && sortBy(Object.values(firebrigades), ['short_name'])
)
