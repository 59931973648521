import { incidentArraySchema, incidentSchema } from '../schemas'
import fetchAndNormalize from '../lib/fetchAndNormalize'
import { getJwt } from './jwt'

// Actions
const actionPrefix = 'fire-editor/incidents'
export const INCIDENT_SELECTED = `${actionPrefix}/INCIDENT_SELECTED`
export const INCIDENT_LIST_CHANGE_PAGE = `${actionPrefix}/INCIDENT_LIST_CHANGE_PAGE`
export const INCIDENT_FORM_STEP = `${actionPrefix}/INCIDENT_FORM_STEP`

export const REQUEST_INCIDENTS_STARTED = `${actionPrefix}/REQUEST_INCIDENTS_STARTED`
export const REQUEST_INCIDENTS_SUCCESSED = `${actionPrefix}/REQUEST_INCIDENTS_SUCCESSED`
export const REQUEST_INCIDENTS_FAILED = `${actionPrefix}/REQUEST_INCIDENTS_FAILED`

export const REQUEST_INCIDENT_STARTED = `${actionPrefix}/REQUEST_INCIDENT_STARTED`
export const REQUEST_INCIDENT_SUCCESSED = `${actionPrefix}/REQUEST_INCIDENT_SUCCESSED`
export const REQUEST_INCIDENT_FAILED = `${actionPrefix}/REQUEST_INCIDENT_FAILED`

export const SAVE_INCIDENT_STARTED = `${actionPrefix}/SAVE_INCIDENT_STARTED`
export const SAVE_INCIDENT_SUCCESSED = `${actionPrefix}/SAVE_INCIDENT_SUCCESSED`
export const SAVE_INCIDENT_FAILED = `${actionPrefix}/SAVE_INCIDENT_FAILED`

export const CREATE_INCIDENT_STARTED = `${actionPrefix}/CREATE_INCIDENT_STARTED`
export const CREATE_INCIDENT_SUCCESSED = `${actionPrefix}/CREATE_INCIDENT_SUCCESSED`
export const CREATE_INCIDENT_FAILED = `${actionPrefix}/CREATE_INCIDENT_FAILED`

export const ADD_MEDIA_TO_INCIDENT_STARTED = `${actionPrefix}/ADD_MEDIA_TO_INCIDENT_STARTED`
export const ADD_MEDIA_TO_INCIDENT_SUCCESSED = `${actionPrefix}/ADD_MEDIA_TO_INCIDENT_SUCCESSED`
export const ADD_MEDIA_TO_INCIDENT_FAILED = `${actionPrefix}/ADD_MEDIA_TO_INCIDENT_FAILED`

export const REMOVE_MEDIA_FROM_INCIDENT_STARTED = `${actionPrefix}/REMOVE_MEDIA_FROM_INCIDENT_STARTED`
export const REMOVE_MEDIA_FROM_INCIDENT_SUCCESSED = `${actionPrefix}/REMOVE_MEDIA_FROM_INCIDENT_SUCCESSED`
export const REMOVE_MEDIA_FROM_INCIDENT_FAILED = `${actionPrefix}/REMOVE_MEDIA_FROM_INCIDENT_FAILED`

// Reducer
const initialState = {
  allIncidents: [],
  page: 0,
  itemsPerPage: 50,
  formStep: 0
}

const incidents = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_INCIDENTS_SUCCESSED:
      return {
        ...state,
        allIncidents: action.result
      }
    case INCIDENT_SELECTED:
      return {
        ...state,
        selectedIncident: action.incidentId
      }
    case INCIDENT_LIST_CHANGE_PAGE:
      return {
        ...state,
        page: action.page
      }
    case INCIDENT_FORM_STEP:
      return {
        ...state,
        formStep: action.step
      }
    case SAVE_INCIDENT_SUCCESSED:
    case CREATE_INCIDENT_SUCCESSED:
      return {
        ...state,
        formStep: 0
      }

    default:
      return state
  }
}

export default incidents

// Action Creator

export const selectedIncident = (incidentId) => ({type: INCIDENT_SELECTED, incidentId})
export const changeIncidentListPage = (page) => ({type: INCIDENT_LIST_CHANGE_PAGE, page})
export const changeFormStep = (step) => ({type: INCIDENT_FORM_STEP, step})

export const requestIncidents = () => async (dispatch, getStore) => {
  dispatch({ type: REQUEST_INCIDENTS_STARTED })

  const jwt = getJwt(getStore())

  try {
    return dispatch({
      type: REQUEST_INCIDENTS_SUCCESSED,
      ...await fetchAndNormalize('/api/admin/incidents', incidentArraySchema, {jwt})
    })
  } catch (error) {
    dispatch({ type: REQUEST_INCIDENTS_FAILED, error })
  }
}

export const requestIncident = (id) => async (dispatch, getStore) => {
  dispatch({ type: REQUEST_INCIDENT_STARTED, incidentId: id })

  const jwt = getJwt(getStore())

  try {
    return dispatch({
      type: REQUEST_INCIDENT_SUCCESSED,
      incidentId: id,
      ...await fetchAndNormalize(`/api/admin/incidents/${id}`, incidentSchema, {jwt})
    })
  } catch (error) {
    dispatch({ type: REQUEST_INCIDENT_FAILED, error })
  }
}

export const saveIncident = (id, incident) => async (dispatch, getStore) => {
  dispatch({ type: SAVE_INCIDENT_STARTED })

  const jwt = getJwt(getStore())

  try {
    const savedData = await fetchAndNormalize(`/api/admin/incidents/${id}`, incidentSchema, {
      method: 'PUT',
      body: incident,
      jwt
    })

    return dispatch({
      type: SAVE_INCIDENT_SUCCESSED,
      ...savedData
    })
  } catch (error) {
    dispatch({ type: SAVE_INCIDENT_FAILED, error })
  }
}

export const createIncident = (incident) => async (dispatch, getStore) => {
  dispatch({ type: CREATE_INCIDENT_STARTED })

  const jwt = getJwt(getStore())

  try {
    const createdData = await fetchAndNormalize(`/api/admin/incidents`, incidentSchema, {
      method: 'Post',
      body: incident,
      jwt
    })

    return dispatch({
      type: CREATE_INCIDENT_SUCCESSED,
      ...createdData
    })
  } catch (error) {
    dispatch({ type: CREATE_INCIDENT_FAILED, error })
  }
}

export const addImageToIncident = (id, file) => async (dispatch, getStore) => {
  dispatch({ type: ADD_MEDIA_TO_INCIDENT_STARTED })

  const jwt = getJwt(getStore())

  const data = new window.FormData()
  data.append('file', file)

  try {
    const changedData = await fetchAndNormalize(`/api/admin/incidents/${id}/media`, incidentSchema, {
      method: 'POST',
      body: data,
      jwt
    })
    return dispatch({
      type: ADD_MEDIA_TO_INCIDENT_SUCCESSED,
      ...changedData
    })
  } catch (error) {
    dispatch({ type: ADD_MEDIA_TO_INCIDENT_FAILED, error })
  }
}

export const removeMediaFromIncident = (id, imageId) => async (dispatch, getStore) => {
  dispatch({ type: REMOVE_MEDIA_FROM_INCIDENT_STARTED })

  const jwt = getJwt(getStore())

  try {
    const changedData = await fetchAndNormalize(`/api/admin/incidents/${id}/media/${imageId}`, incidentSchema, {
      method: 'DELETE',
      jwt
    })
    return dispatch({
      type: REMOVE_MEDIA_FROM_INCIDENT_SUCCESSED,
      ...changedData
    })
  } catch (error) {
    dispatch({ type: REMOVE_MEDIA_FROM_INCIDENT_FAILED, error })
  }
}

// Selectors

export const loginError = (state) => state.incidents.error
export const getSelectedIncident = (state) => state.incidents.selectedIncident
export const getPage = (state) => state.incidents.page
export const getFormStep = (state) => state.incidents.formStep
export const getItemsPerPage = (state) => state.incidents.itemsPerPage

const fillIdsWithIncidents = (ids, byId) => ids
  .map(id => byId[id])
  .map(incident => ({
    ...incident,
    alerting_at: new Date(incident.alerting_at),
    exit_at: new Date(incident.exit_at),
    return_at: new Date(incident.return_at)
  }))

export const getIncidentsCount = (state) => state.incidents.allIncidents.length

export const getIncidentsPerPage = (state, byId) => {
  return fillIdsWithIncidents(
    state.incidents.allIncidents
      .slice(state.incidents.page * state.incidents.itemsPerPage, ((state.incidents.page + 1) * state.incidents.itemsPerPage - 1)),
    byId
  )
}

export const getIncidents = (state, byId) => fillIdsWithIncidents(state.incidents.allIncidents, byId)

export const getIncidentById = (state, incidentId) => {
  const incident = state.entities.incident[incidentId]
  if (!incident) {
    return
  }

  return {
    ...incident,
    alerting_at: new Date(incident.alerting_at),
    exit_at: new Date(incident.exit_at),
    return_at: new Date(incident.return_at)
  }
}
