// Actions
export const REQUEST_USER_TOKEN_STARTED = 'REQUEST_USER_TOKEN_STARTED'
export const REQUEST_USER_TOKEN_SUCCESSED = 'REQUEST_USER_TOKEN_SUCCESSED'
export const REQUEST_USER_TOKEN_FAILED = 'REQUEST_USER_TOKEN_FAILED'

// Reducer
const passwordlessLogin = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_USER_TOKEN_STARTED:
      return {
        isFetching: true,
        email: action.email,
        error: undefined
      }
    case REQUEST_USER_TOKEN_SUCCESSED:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        requestOk: true
      }
    case REQUEST_USER_TOKEN_FAILED:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }

    default:
      return state
  }
}

export default passwordlessLogin

// Action creator
export const requestUserToken = (email) => (dispatch) => {
  dispatch({ type: REQUEST_USER_TOKEN_STARTED, email })
  return window.fetch('/api/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error("Request didn't send an ok status. Status is " + response.status)
      }
    })
    .then(
      ({ok}) => dispatch(ok ? { type: REQUEST_USER_TOKEN_SUCCESSED } : { type: REQUEST_USER_TOKEN_FAILED }),
      (error) => dispatch({ type: REQUEST_USER_TOKEN_FAILED, error })
    )
}

// Selector
export const loginError = (state) => state.passwordlessLogin.error
