import React from 'react'
import Checkbox from 'material-ui/Checkbox'

let FormItemList = ({
    dataSource,
    dataField,
    labelField,
    input: { value, onChange },
    meta: { dirty, touched, error, warning }
  }) => {
  const handleToggle = (itemValue, isInputChecked) => {
    value = value || []
    if (!isInputChecked) {
      return value.filter((item) => itemValue !== item)
    } else {
      return [...value, itemValue]
    }
  }

  return (
    <div>
      {dataSource.map(item => {
        let label = ''
        switch (typeof labelField) {
          case 'function':
            label = labelField(item)
            break
          default:
            label = item[labelField]
        }

        return (
          <Checkbox
            key={item[dataField]}
            label={label}
            checked={value.includes(item[dataField])}
            onCheck={(event, isInputChecked) => onChange(handleToggle(item[dataField], isInputChecked))}
          />
        )
      })}
      {
        dirty && error
        ? <p>{error}</p>
        : undefined
      }
      {
        dirty && warning
        ? <p>{warning}</p>
        : undefined
      }
    </div>
  )
}

export default FormItemList
