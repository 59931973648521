import sortBy from 'lodash/sortBy'
import { createSelector } from 'reselect'

import { districtArraySchema } from '../schemas'
import fetchAndNormalize from '../lib/fetchAndNormalize'
import { getJwt } from '../ducks/jwt'

const actionPrefix = 'fire-editor/districts'

// Actions
export const REQUEST_DISTRICTS_STARTED = `${actionPrefix}/LOAD_STARTED`
export const REQUEST_DISTRICTS_SUCCESSED = `${actionPrefix}/LOAD_SUCCESSED`
export const REQUEST_DISTRICTS_FAILED = `${actionPrefix}/LOAD_FAILED`

// Reducer
export default function reducer (state = {}, action = {}) {
  switch (action.type) {
    default: return state
  }
}

// Action Creators
export const requestDistricts = () => async (dispatch, getStore) => {
  dispatch({ type: REQUEST_DISTRICTS_STARTED })

  const jwt = getJwt(getStore())

  try {
    const data = await fetchAndNormalize('/api/admin/districts', districtArraySchema, {jwt})
    dispatch({
      type: REQUEST_DISTRICTS_SUCCESSED,
      ...data
    })
  } catch (error) {
    dispatch({ type: REQUEST_DISTRICTS_FAILED, error })
  }
}

// Selectors
export const getDistricts = (state) => state.entities.district
export const getDistrictsAsList = createSelector(
  [getDistricts],
  (districts) => districts && sortBy(Object.values(districts), ['is_foreign', 'name'])
)
