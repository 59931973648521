import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import reducer from "../ducks";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
  const middleware = [thunk];
  if (process.env.NODE_ENV !== "production") {
    middleware.push(logger);
  }

  const store = createStore(
    reducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  return store;
}
