import React, { Component } from "react";
import { connect } from "react-redux";
import { getFormValues } from "redux-form";
import { Redirect } from "react-router-dom";

import {
  requestNews,
  saveNewsItem,
  changeNewsFormStep,
  getNewsItemById,
  getFormStep
} from "../ducks/news";

import { requestDistricts, getDistrictsAsList } from "../ducks/districts";
import {
  requestFirebrigades,
  getFirebrigadesAsList
} from "../ducks/firebrigades";

import NewsItemForm from "./NewsItemForm";

class NewsItemEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
  }

  componentDidMount() {
    this.props.requestNews(this.props.newsId);
    this.props.requestDistricts();
    this.props.requestFirebrigades();
    this.props.changeNewsFormStep(0);
  }

  render() {
    const {
      newsId,
      newsItem,
      districts,
      firebrigades,
      value,
      saveNewsItem,
      step,
      changeNewsFormStep
    } = this.props;

    const handleSubmit = newsItem =>
      saveNewsItem(newsId, newsItem).then(() => {
        this.setState({ submitted: true });
      });

    return (
      <div>
        {this.state.submitted && <Redirect to="/news" />}
        {!this.state.submitted && newsItem && districts && firebrigades && (
          <NewsItemForm
            initialValues={newsItem}
            districts={districts}
            firebrigades={firebrigades}
            value={value}
            step={step}
            onNextStep={changeNewsFormStep}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  newsId: ownProps.match.params.newsId,
  newsItem: getNewsItemById(state, ownProps.match.params.newsId),
  districts: getDistrictsAsList(state),
  firebrigades: getFirebrigadesAsList(state),
  step: getFormStep(state),
  value: getFormValues("incidentForm")(state)
});

export default connect(
  mapStateToProps,
  {
    requestNews,
    requestDistricts,
    requestFirebrigades,
    saveNewsItem,
    changeNewsFormStep
  }
)(NewsItemEdit);
