import { createSelector } from 'reselect'
import { normalize } from 'normalizr'

import { operationTypeArraySchema } from '../schemas'
import fetchWithJwt from '../lib/fetchWithJwt'
import { getJwt } from '../ducks/jwt'

// Actions
export const REQUEST_OPERATION_TYPES_STARTED = 'REQUEST_OPERATION_TYPES_STARTED'
export const REQUEST_OPERATION_TYPES_SUCCESSED = 'REQUEST_OPERATION_TYPES_SUCCESSED'
export const REQUEST_OPERATION_TYPES_FAILED = 'REQUEST_OPERATION_TYPES_FAILED'

// Action Creators
export const requestOperationTypes = () => (dispatch, getStore) => {
  dispatch({ type: REQUEST_OPERATION_TYPES_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt('/api/admin/operation-types', {jwt})
    .then(response => response.json())
    .then(
      (incidentsTypes) => dispatch({
        type: REQUEST_OPERATION_TYPES_SUCCESSED,
        ...normalize(incidentsTypes, operationTypeArraySchema)
      }),
      (error) => dispatch({ type: REQUEST_OPERATION_TYPES_FAILED, error })
    )
}

// Selectors
export const getOperationTypes = (state) => state.entities.operationType
export const getOperationTypesAsList = createSelector(
  [getOperationTypes],
  (operationTypes) => operationTypes && Object.values(operationTypes)
)
