import React from 'react'

import {
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps/lib'

const getLatLng = ({lat, lng}) => [lat(), lng()]
const defaultStartPoint = { lat: 51.7623, lng: 6.1400 }

const MapInput = withGoogleMap(({coordinates}) => {
  const value = coordinates.map(({input}) => input.value)
  const hasValues = Array.isArray(value) && value.length === 2 && value.every(v => typeof v === 'number' && v !== 0)

  const handleChangeEvery = (val) => coordinates
    .forEach(({input}, i) => input.onChange(val[i]))

  const handleChange = ({latLng}) => {
    let coords = getLatLng(latLng)
    handleChangeEvery(coords)
  }
  const position = hasValues ? { lat: value[0], lng: value[1] } : defaultStartPoint

  const handleClick = ({latLng}) => !hasValues && handleChangeEvery(getLatLng(latLng))

  return (
    <GoogleMap
      defaultZoom={hasValues ? 17 : 12}
      defaultCenter={position}
      onClick={handleClick}
    >
      { hasValues
        ? <Marker
          position={{lat: value[0], lng: value[1]}}
          draggable
          onDragEnd={handleChange}
        />
        : null
      }
    </GoogleMap>
  )
})

export default MapInput
