import React from "react";
import { GridList, GridTile } from "material-ui/GridList";
import Paper from "material-ui/Paper";
import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete";

import { Flex, Box } from "reflexbox";

const ImagesList = ({ media, onDelete }) => {
  return (
    <Paper>
      <Flex wrap justify="space-around">
        <Box m={2} w={[1]} style={{ maxWidth: 600 }}>
          {!media && <div>Es gibt keine Medien</div>}
          {media && (
            <GridList cols={2} style={{ width: "100%" }} cellHeight={300}>
              {media.map((tile, i) => (
                <GridTile
                  key={tile.fileName}
                  actionIcon={
                    <IconButton
                      tooltip="Löschen"
                      onClick={() => onDelete(tile._id)}
                    >
                      <DeleteIcon color="white" />
                    </IconButton>
                  }
                  title={tile.position}
                >
                  <img
                    src={`https://images.feuerwehrbedburghau.de/x300/${
                      tile.fileName
                    }`}
                    alt={`Bild Nummer ${i + 1}`}
                  />
                </GridTile>
              ))}
            </GridList>
          )}
        </Box>
      </Flex>
    </Paper>
  );
};

export default ImagesList;
