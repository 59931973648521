import React from "react";
import { Link } from "react-router-dom";
import FloatingActionButton from "material-ui/FloatingActionButton";
import AddIcon from "material-ui/svg-icons/content/add";

const FloatingNewButton = ({ to }) => (
  <FloatingActionButton
    style={{ position: "absolute", right: 20, bottom: 20 + 51 }}
    containerElement={<Link to={to} />}
  >
    <AddIcon />
  </FloatingActionButton>
);

export default FloatingNewButton;
