import React from "react";
import { connect } from "react-redux";

import { Route, BrowserRouter as Router } from "react-router-dom";

import ThemeProvider from "./ThemeProvider";
import MainMenu from "../components/MainMenu";

import Home from "../containers/Home";
import MatchWhenAuthorized from "../containers/MatchWhenAuthorized";
import LoginAuth from "../containers/LoginAuth";

import IncidentsModule from "../components/IncidentsModule";

import NewsList from "../containers/NewsList";
import NewsItemEdit from "../containers/NewsItemEdit";
import NewsItemImages from "../containers/NewsItemImages";
import NewsItemCreate from "../containers/NewsItemCreate";
import LoginDialog from "../containers/LoginDialog";

import { isSideMenuOpened, toggleNavigation } from "../ducks/sideMenu";
import { isLoggedIn } from "../ducks/jwt";

const App = ({ sideMenuIsShowen, toggleNavigation, isLoggedIn }) => (
  <ThemeProvider>
    <Router>
      <MainMenu
        open={sideMenuIsShowen}
        onToggleNavigation={toggleNavigation}
        isLoggedIn={isLoggedIn}
      >
        <div>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact component={LoginDialog} />
          <Route path="/login/token" exact component={LoginAuth} />

          <MatchWhenAuthorized path="/incidents" component={IncidentsModule} />

          <MatchWhenAuthorized path="/news" exact component={NewsList} />
          <MatchWhenAuthorized
            path="/news/new"
            exact
            component={NewsItemCreate}
          />
          <MatchWhenAuthorized
            path="/news/:newsId"
            exact
            component={NewsItemEdit}
          />
          <MatchWhenAuthorized
            path="/news/:newsId/images"
            exact
            component={NewsItemImages}
          />
        </div>
      </MainMenu>
    </Router>
  </ThemeProvider>
);

const mapStateToProps = state => ({
  sideMenuIsShowen: isSideMenuOpened(state),
  isLoggedIn: isLoggedIn(state)
});

export default connect(
  mapStateToProps,
  {
    toggleNavigation
  }
)(App);
