import React, { Component } from "react";
import { connect } from "react-redux";

import ImagesList from "../components/ImagesList";
import ImageDropzone from "../components/ImageDropzone";

import { Flex, Box } from "reflexbox";

import {
  requestIncident,
  addImageToIncident,
  removeMediaFromIncident,
  getIncidentById
} from "../ducks/incidents";

class IncidentImages extends Component {
  componentDidMount() {
    this.props.requestIncident(this.props.incidentId);
  }

  render() {
    const {
      incidentId,
      incident,
      addImageToIncident,
      removeMediaFromIncident
    } = this.props;
    if (!incident) {
      return <div />;
    }

    const { media } = incident;

    const onDrop = ([file]) => addImageToIncident(incidentId, file);

    const onDelete = imageId => removeMediaFromIncident(incidentId, imageId);

    return (
      <Flex column>
        {media && media.length > 0 ? (
          <Box p={2}>
            <ImagesList media={media} onDelete={onDelete} />
          </Box>
        ) : null}
        <Box p={2}>
          <ImageDropzone onDrop={onDrop} />
        </Box>
      </Flex>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  incidentId: ownProps.match.params.incidentId,
  incident: getIncidentById(state, ownProps.match.params.incidentId)
});

export default connect(
  mapStateToProps,
  {
    requestIncident,
    addImageToIncident,
    removeMediaFromIncident
  }
)(IncidentImages);
