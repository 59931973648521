import { normalize } from 'normalizr'
import { createSelector } from 'reselect'

import { personArraySchema } from '../schemas'
import fetchWithJwt from '../lib/fetchWithJwt'
import { getJwt } from '../ducks/jwt'

// Actions
export const REQUEST_PEOPLE_STARTED = 'REQUEST_PEOPLE_STARTED'
export const REQUEST_PEOPLE_SUCCESSED = 'REQUEST_PEOPLE_SUCCESSED'
export const REQUEST_PEOPLE_FAILED = 'REQUEST_PEOPLE_FAILED'

// Action Creators
export const requestPeople = () => (dispatch, getStore) => {
  dispatch({ type: REQUEST_PEOPLE_STARTED })

  const jwt = getJwt(getStore())

  return fetchWithJwt('/api/admin/people', {jwt})
    .then(response => response.json())
    .then(
      (people) => dispatch({
        type: REQUEST_PEOPLE_SUCCESSED,
        ...normalize(people, personArraySchema)
      }),
      (error) => dispatch({ type: REQUEST_PEOPLE_FAILED, error })
    )
}

// Selectors
export const getPeople = (state) => state.entities.person
export const getPeopleAsList = createSelector(
  [getPeople],
  (people) => people && Object.values(people)
)
